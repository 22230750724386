<template>
  <div class="scheme-contain">
    <div class="affixion-contain">
      <Form :label-width="115">
        <Row>
          <Col span="8" offset="8">
            <Date-picker type="date" v-model="doneForm.service_time" placeholder="选择日期" @on-change="changeDate" class="mb40"></Date-picker>
          </Col>
        </Row>
        <Row class="field-name">
          会员K值记录
        </Row>
        <Row>
          <template v-for="v in k_type">
            <Col :lg="{span: 3}" :md="{span: 6}" :key="v.name">
              <Checkbox-group v-model="doneForm.service_type">
                <Checkbox :label="v.id" :title="v.name">{{ v.name }}</Checkbox>
              </Checkbox-group>
            </Col>
          </template>
        </Row>
        <Row class="field-name">
          会员服务记录
        </Row>
        <Row>
          <template v-for="v in service_type">
            <Col :lg="{span: 3}" :md="{span: 6}" :key="v.name">
              <Checkbox-group v-model="doneForm.service_type">
                <Checkbox :label="v.id" :title="v.name">{{ v.name }}</Checkbox>
              </Checkbox-group>
            </Col>
          </template>

            <!-- <Form-item label="服务项目：">
              <Select multiple clearable v-model="doneForm.service_type" placeholder="请选择">
                <Option :value="v.id" v-for="v in service_type" :key="v">{{v.name}}</Option>
              </Select>
            </Form-item> -->
            <!-- <Form-item label="详细情况：">
              <Input v-model="doneForm.detail" placeholder="请输入..." type="textarea" :autosize="{minRows: 8, maxRows: 8}"></Input>
            </Form-item> -->
        </Row>
        <div class="symbol-box tac mt30">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <Button type="success" size="large" @click="handleSave">保存</Button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import servicesService from '@/services/servicesService';
export default {
	data() {
		return {
			service_type: [],
			k_type: [],
			doneProblem: [],
			problem: [],
			shouldList: [
				{
					id: '0',
					name: '已解决',
				},
				{
					id: '1',
					name: '上报',
				},
			],
			doneForm: {
				service_type: [],
				detail: '',
				member_id: this.$route.params.member_id,
				data_string: '',
				service_string: '',
				service_time: '',
			},
		};
	},
	created() {
		servicesService.donePageServices().then((data) => {
			this.problem = data.problem_list;
			this.service_type = data.service_type;
			this.k_type = data.service_type1;
		});
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleBack() {
			this.$router.go(-1);
		},
		newProblem() {
			this.doneProblem.push({
				source: '促进',
				member_id: '',
				level1_id: '',
				level2_id: '',
				detail_info: '',
				should_report: '0',
				idd: [],
			});
		},
		// 时间改变
		changeDate(date) {
			this.doneForm.service_time = date;
			// 在获取数量和状态填充
		},
		removePro(index) {
			this.doneProblem.splice(index, 1);
		},
		handleSave() {
			if (this.validateFormData()) {
				for (var i = 0; i < this.doneProblem.length; i++) {
					this.doneProblem[i].member_id = this.memberId;
					this.doneProblem[i].level1_id = this.doneProblem[i].idd[0]
						? this.doneProblem[i].idd[0]
						: '';
					this.doneProblem[i].level2_id = this.doneProblem[i].idd[1]
						? this.doneProblem[i].idd[1]
						: '';
					delete this.doneProblem[i].idd;
				}
				this.doneForm.data_string = JSON.stringify(this.doneProblem);
				this.doneForm.service_string = JSON.stringify(this.doneForm.service_type);
				servicesService.addServices(this.doneForm).then(() => {
					this.$router.push({
						path: '/base/member/detail/' + this.$route.params.member_id + '/Services/19',
					});
				});
			}
		},
		validateFormData() {
			if (!this.doneForm.service_time) {
				this.$Message.warning('请选择日期');
				return false;
			}

			return true;
			// for (var i = 0; i < this.doneProblem.length; i++) {
			//   if (!this.doneProblem[i].idd[0]) {
			//     this.$Message.warning('请选择各问题单的咨询种类')
			//     return false
			//   }
			// }
			// return true
		},
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
  padding: 0 20px;
}
.scheme-contain .add-problem {
  padding: 10px 30px;
}
.scheme-contain .add-problem .add-btn {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding-left: 26px;
  background: url(@/assets/img/icon_add.png) no-repeat left;
}
.scheme-contain .commom-panel-two {
  margin: 0 10px;
}
.mt30 {margin-top: 30px;}
.mb40 {margin-bottom: 40px;}
.field-name {color:#fcad1e;margin: 10px 0;}
</style>
